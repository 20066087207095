import "../../styles/services/services-hero.css";


function ServicesHero() {
    return (
        <div className="services-hero-content">
            <div className="services-bg-img">
                
            </div>
            <div className="services-text">
                <h1>Our Services</h1>
            </div>
        </div>
    );
}

export default ServicesHero;
